export function useProductPrice (clientData, productData) {
  const target = productData.customProductPrice ? productData : clientData

  function toFixed (number) {
    return Math.round(number * 100) / 100
  }

  const minPrice = () => {
    const purchaseShipping = (productData.purchasePrice + target.shippingFee)
    const withPercentage = (((100 + target.minPricePercentage) / 100) * purchaseShipping)
    return toFixed(withPercentage + target.minPriceSum)
  }

  const maxPrice = () => {
    const withPercentage = (((100 + target.maxPricePercentage) / 100) * minPrice())
    return toFixed(withPercentage + target.maxPriceSum)
  }

  return {
    target,
    minPrice,
    maxPrice
  }
}
