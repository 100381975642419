<template>
  <div class="load-container">
    <img class="v-logo" src="../../../assets/logo.png" />
    <Loading class="v-loader" />
  </div>
</template>

<script>
import Loading from './loading.svg'
export default {
  name: 'Loader',
  components: {
    Loading
  }
}
</script>

<style lang="scss" scoped>
.load-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--background);
  z-index: 500;
  .v-logo {
    width: 150px;
    height: 150px;
    margin-bottom: 50px;
  }
  .v-loader {
    width: 50px;
    height: 50px;
  }
}
</style>
