<template>
  <div class="products-edit-multiple-container">
    <div class="warning">
      Bij het opslaan worden alle geselecteerde producten:
      <br />
      - Crawlen {{ state.enabled ? 'ingeschakeld' : 'uitgeschakeld' }}.
      <br />
      - PriceTiger {{ state.automaticPrice ? 'ingeschakeld' : 'uitgeschakeld' }}.
    </div>
    <div class="section">
      <div class="row">
        <div class="column head">Crawlen</div>
        <div class="column">
          <VSwitch
            v-model="state.enabled"
          />
        </div>
      </div>
      <div class="row">
        <div class="column head">PriceTiger</div>
        <div class="column">
          <VSwitch
            v-model="state.automaticPrice"
          />
        </div>
      </div>
    </div>
    <div class="buttons">
      <div class="ok-button">
        <VButton @click="save" :loading="state.loading">Opslaan</VButton>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import VButton from '../ComponentFramework/Button/VButton.vue'
import { useModal } from '../ComponentFramework/Modal/Modal.js'
import VSwitch from '../ComponentFramework/Switch/VSwitch.vue'
import { useProducts } from './useProducts.js'
import firebase from '../../firebase.js'

export default {
  name: 'ProductsEditMultiple',
  components: {
    VButton,
    VSwitch
  },
  props: {
    manager: {
      type: Object,
      default: () => {
        return {}
      }
    },
    selectedProducts: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup (props) {
    const modal = useModal()
    const { toggleEnable, updateProduct } = useProducts()
    const state = reactive({
      enabled: false,
      automaticPrice: false,
      loading: false
    })

    async function save () {
      state.loading = true

      await firebase.firestore().runTransaction(async transaction => {
        for (const product of props.selectedProducts) {
          await toggleEnable(product.id, state.enabled, transaction)
          await updateProduct(product.id, { automaticPrice: state.automaticPrice }, transaction)
        }
      })

      state.loading = false
      modal.options.onOk()
      modal.close()
    }

    return {
      state,
      save
    }
  }
}
</script>

<style lang="scss" scoped>
.warning {
  color: #4D4D4D;
  font-size: 13px;
  margin-bottom: 10px;
  font-style: italic;
  line-height: 1.2;
  width: 550px;
}
.products-edit-multiple-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;

  .buttons {
    display: flex;
    width: 100%;
    margin-top: auto;

    .cancel-button {
      margin-right: auto;
    }

    .ok-button {
      margin-left: auto;
    }
  }

  .section:not(:first-child) {
    margin-top: 15px;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 30px;
    margin-bottom: 8px;

    &.input-row {
      margin-bottom: 10px;
    }

    .column {
      font-size: 14px;
      color: #4F4F4F;

      &.head {
        width: 135px;
        font-weight: bold;

        &:not(:first-child) {
          margin-left: 25px;
        }
      }
    }
  }
}
</style>
