<template>
  <div v-if="!extensions.length" class="warning">
    Er zijn geen extensies uitgesloten waardoor er geen filter wordt toegepast.
    Een website die Nederlands als beschikbare taal opgeeft
    zal dus worden meegerekend terwijl het bijv. een Poolse extensie heeft (.pl).
  </div>
  <div class="row input-row">
    <div class="column head">Beschikbare extensies</div>
    <div class="column extension-select">
      <VSelect
        v-model:value="input"
        :placeholder="unselectedExtensions.length ? 'Kies een extensie' : 'Geen extensies beschikbaar'"
      >
        <option
          v-for="extension in unselectedExtensions"
          :value="extension"
          :key="extension"
        >
          {{ extension }}
        </option>
      </VSelect>
      <VButton :disabled="!input" @click="add">Toevoegen</VButton>
    </div>
  </div>
  <div class="selected-extensions">
    <div
      v-for="(extension, idx) in extensions"
      :key="extension"
      class="selected-extension"
    >
      <div>{{ extension }}</div>
      <font-awesome-icon class="icon" @click="() => remove(idx)" icon="trash" />
    </div>
  </div>
</template>

<script>
import VButton from '../ComponentFramework/Button/VButton.vue'
import VSelect from '../ComponentFramework/Select/VSelect.vue'
import { useExtensionWhitelist } from './useExtensionWhitelist.js'

export default {
  name: 'ExtensionWhitelist',
  components: { VSelect, VButton },
  setup () {
    const {
      input,
      add,
      remove,
      unselectedExtensions,
      extensions
    } = useExtensionWhitelist()

    return {
      extensions,
      unselectedExtensions,
      input,
      add,
      remove
    }
  }
}
</script>

<style lang="scss" scoped>
.warning {
  color: #4D4D4D;
  font-size: 13px;
  margin-bottom: 10px;
  font-style: italic;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 30px;
  margin-bottom: 8px;

  &.input-row {
    margin-bottom: 10px;
  }

  .column {
    font-size: 14px;
    color: #4F4F4F;

    .select-container {
      display: flex;
    }

    &.head {
      width: 170px;
      font-weight: bold;

      &:not(:first-child) {
        margin-left: 25px;
      }
    }

    &.extension-select {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;

      .select-container {
        &::v-deep(.select) {
          width: 100%;
        }
        margin-right: 10px;
      }
    }
  }
}
.selected-extensions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.selected-extension {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 5px;

  div {
    color: #3B8BE5;
    font-size: 12px;
    margin-right: 5px;
  }

  .icon {
    font-size: 10px;
    color: #979797;
    cursor: pointer;
  }
}
</style>
