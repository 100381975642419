import { ref, watch } from 'vue'

export const inputMixin = {
  emits: ['update:value'],
  props: {
    value: {
      default: ''
    }
  }
}

export const selectMixin = {
  emits: ['update:value'],
  props: {
    value: {
      default: null
    }
  }
}

export const tagInputMixin = {
  emits: ['update:value'],
  props: {
    value: {
      type: Array,
      default: () => []
    }
  }
}

export const autoCompleteMixin = {
  emits: ['update:modelValue', 'update:searchValue'],
  props: {
    value: {
      type: Object,
      default: null
    },
    modelValue: {
      type: null,
      default: null
    }
  }
}

export const checkboxMixin = {
  emits: ['update:modelValue'],
  props: {
    initialValue: {
      default: false
    },
    modelValue: {
      default: false
    },
    value: {
      type: null,
      default: true
    },
    trueValue: {
      type: null,
      default: true
    },
    falseValue: {
      type: null,
      default: false
    }
  }
}

export const radioMixin = {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      default: false
    },
    value: {
      type: null,
      default: true
    },
    trueValue: {
      type: null,
      default: true
    },
    falseValue: {
      type: null,
      default: false
    }
  }
}

/**
 * Todo we should not pass all the props so this composable handles "the magic".
 * Instead we should pass only required fields.
 */
export function useValue (props, emit) {
  const valueRef = ref(typeof props.initialValue !== 'undefined' ? props.initialValue : typeof props.modelValue !== 'undefined' ? props.modelValue : props.value)

  const type = typeof props.modelValue !== 'undefined' ? 'modelValue' : 'value'
  const emitType = 'update:' + type

  watch(valueRef, (n) => props[type] !== n && emit(emitType, n))
  watch(() => props[type], (n) => (valueRef.value = n))

  return {
    valueRef
  }
}
