<template>
  <div class="switch-container" :class="[{
    'is-hovered': hovered,
    'is-disabled': disabled,
  }, $attrs.class]"
  >
    <div class="checkbox-wrapper">
      <input
        :id="id"
        v-model="valueRef"
        type="checkbox"
        class="checkbox-input"
        :value="value"
        :true-value="trueValue"
        :false-value="falseValue"
        :name="name"
      >
      <label :for="id" class="checkbox-custom" />
    </div>
  </div>
</template>

<script>
import { checkboxMixin, useValue } from '../Value/useValue.js'

export default {
  name: 'VSwitch',
  mixins: [checkboxMixin],
  props: {
    name: {
      type: [String, Boolean],
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hovered: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const id = Math.random()
    const { valueRef } = useValue(props, emit)

    return {
      valueRef,
      id
    }
  }
}
</script>

<style lang='scss' scoped>
.switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover, &.is-hovered {
    //Show check if checkbox is hovered
    .checkbox-wrapper .checkbox-input + .checkbox-custom:after {
      //opacity: 0.25;
    }
  }

  &[disabled], &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    .checkbox-wrapper .checkbox-custom, .checkbox-input, .checkbox-label {
      cursor: not-allowed;
    }
  }

  .v-label {
    line-height: 16px;
    font-size: 14px;
    color: #4F4F4F;
    cursor: pointer;
    user-select: none;

    &.is-right {
      margin-left: 10px;
    }
    &.is-left {
      margin-right: 10px;
    }
  }

  .checkbox-wrapper {
    position: relative;

    //DEFAULT STATE (the checkbox container)
    .checkbox-custom {
      height: 22px;
      width: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      line-height: 16px;
      -webkit-font-smoothing: antialiased;
      color: gray;
      background-color: white;
      text-align: center;
      cursor: pointer;
      user-select: none;
    }

    //THE REAL HTML5 CHECKBOX (hide it)
    .checkbox-input[type=checkbox] {
      display: none;
    }

    //DEFAULT STATE (the check)
    .checkbox-input + .checkbox-custom {
      border: solid 1px #979797;
      transition: all ease 100ms;

      &:after {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #979797;
        left: 5px;
        transition: left ease 100ms, right ease 100ms;
      }
    }

    //CHECKED STATE
    .checkbox-input:checked + .checkbox-custom {
      border: solid 1px #3CAB51;
      &:after {
        left: calc(100% - 16px - 5px);
        background-color: #3CAB51;
      }
    }
  }
}
</style>
