import { createRouter, createWebHistory } from 'vue-router'
import Login from '../features/Login.vue'
import Products from '../features/Products/Products.vue'
import Settings from '../features/Settings/Settings.vue'

const routes = [
  {
    name: 'base',
    path: '/',
    redirect: { name: 'login' }
  },
  {
    name: 'login',
    path: '/login',
    component: Login
  },
  {
    name: 'start',
    path: '/start',
    redirect: { name: 'products' }
  },
  {
    name: 'products',
    path: '/products',
    component: Products
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
