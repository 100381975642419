import { ref, provide, inject } from 'vue'

const LoaderKey = Symbol('Loader')

function createLoader () {
  const loading = ref(true)

  function isLoading () {
    return loading
  }

  function setLoading (value) {
    loading.value = value
  }

  function hide () {
    loading.value = false
  }

  function show () {
    loading.value = true
  }

  return { loading, show, hide, setLoading, isLoading }
}

export function provideLoader () {
  const loader = createLoader()
  provide(LoaderKey, loader)

  return loader
}

export function useLoader () {
  const loader = inject(LoaderKey)
  if (!loader) {
    throw new Error('No loader provided')
  }

  return loader
}
