<template>
  <div class="v-table-container">
    <table class="v-table">
      <thead>
        <tr>
          <th><input :checked="allSelected" @change="e => $emit('selectAll', e)" type="checkbox" /></th>
          <th>EAN</th>
          <th>Productnaam</th>
          <VTableHeadSortable field="lastCrawl">Laatste crawl</VTableHeadSortable>
          <VTableHeadSortable field="priceStatusOrder">Prijs status</VTableHeadSortable>
          <VTableHeadSortable field="crawlStatus">Crawl status</VTableHeadSortable>
          <VTableHeadSortable field="currentPrice">Huidige prijs</VTableHeadSortable>
          <VTableHeadSortable field="purchasePrice">Inkoop prijs</VTableHeadSortable>
          <VTableHeadSortable field="minPrice">Min prijs</VTableHeadSortable>
          <VTableHeadSortable field="maxPrice">Max prijs</VTableHeadSortable>
          <VTableHeadSortable field="lowestPrice">Laagste prijs</VTableHeadSortable>
          <VTableHeadSortable field="amountCompetitors">Concurrenten</VTableHeadSortable>
          <th colspan="2" class="empty-for-actions"></th>
        </tr>
      </thead>
      <tbody>
        <slot />
      </tbody>
    </table>
  </div>
</template>

<script>
import VTableHeadSortable from './VTableHeadSortable.vue'
export default {
  name: 'VTable',
  components: { VTableHeadSortable },
  emits: ['selectAll'],
  props: {
    loadingText: {
      type: String,
      default: ''
    },
    errorText: {
      type: String,
      default: ''
    },
    emptyText: {
      type: String,
      default: ''
    },
    allSelected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang='scss' scoped>
.v-table-container {
  background-color: white;
  color: $light-text;
  border-radius: 4px;
  padding: 0 25px 15px;
  margin-bottom: 25px;
  margin-top: 20px;
  display: flex;
  width: 100%;

  .v-table {
    width: 100%;
    border-collapse: separate;
    position: relative;
  }

  .empty-for-actions {
    width: 125px;
  }

  thead {
    border-bottom: 1px solid var(--grey-1);
    th {
      padding: 15px 0;
      color: $light-text;
      font-weight: 700;
      text-align: left;
      font-size: 14px;
      transition: none;
      position: sticky;
      top: -18px;
      border-bottom: 1px solid var(--grey-1);
      background: white;
      z-index: 900;
    }
  }

  tbody::v-deep(td) {
    height: 24px;
    vertical-align: middle;
    padding-top: 7px;

    > div {
      display: flex;
      align-items: center;
    }
    font-size: 14px;

    .actions {
      display: flex;
      font-size: 16px;
      > div {
        margin-right: 10px;
      }
      :first-child {
        margin-left: auto;
      }
    }

    &.is-right {
      min-width: 25px;
      max-width: 25px;
      text-align: right;
      padding-right: 5px;
    }
  }
}
</style>
