import { computed } from 'vue'

export function useProductSelect (getProductIndex, products, paginatedResult, loading) {
  const selectedProducts = computed(() => {
    return products.value.filter(product => product.checked)
  })

  const allSelected = computed(() => {
    return !loading.value && !paginatedResult.value.find(product => !product.checked)
  })

  function selectAllProducts (e) {
    paginatedResult.value.forEach(product => {
      selectProduct({ e, product })
    })
  }

  function deselectAll () {
    selectedProducts.value.forEach(product => {
      selectProduct({ e: { target: { checked: false } }, product })
    })
  }

  function selectProduct ({ e, product }) {
    const productIndex = getProductIndex(product.id)
    products.value[productIndex].checked = e.target.checked
  }

  return {
    deselectAll,
    selectedProducts,
    allSelected,
    selectAllProducts,
    selectProduct
  }
}
