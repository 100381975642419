import { ref, reactive, shallowRef, provide, inject } from 'vue'

const ModalKey = Symbol('Modal')

function createModal () {
  const loading = ref(false)
  const visible = ref(false)
  const componentInst = shallowRef(null)

  const defaultOptions = {
    title: 'Modal',
    okIcon: ['fas', 'user-slash'],
    cancelIcon: false,
    okText: 'Ok',
    cancelText: 'Cancel',
    okVariant: 'is-danger',
    cancelVariant: 'is-grey-1',
    onOk: () => null,
    onCancel: () => close(),
    loading: loading
  }

  const options = reactive({ ...defaultOptions })

  const setLoading = v => (loading.value = v)

  function open (component, opts = {}, async = false) {
    componentInst.value = component
    Object.assign(options, opts)
    visible.value = true
    if (async) {
      return new Promise((resolve, reject) => {
        options.onOk = () => resolve()
        options.onCancel = () => reject(new Error('User canceled'))
      })
    }
  }

  function setTitle (title) {
    options.title = title
  }

  function close () {
    visible.value = false
    Object.assign(options, defaultOptions)
  }

  return { open, close, options, visible, setLoading, loading, componentInst, setTitle }
}

export function provideModal () {
  const modal = createModal()
  provide(ModalKey, modal)
  return modal
}

export function useModal () {
  const modal = inject(ModalKey)

  if (!modal) {
    throw new Error('Run provideModal before useModal')
  }

  return modal
}
