<template>
  <div class="status-text" :class="statusClass">
    <span v-if="status !== 'WAITING'">
      {{ status }}
    </span>
    <font-awesome-icon v-else icon="clock" />
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'StatusText',
  props: {
    status: {
      type: String,
      default: 'WAITING'
    }
  },
  setup (props) {
    const statusClass = computed(() => {
      switch (props.status) {
        case 'OK':
          return 'is-success'
        case 'CRAWLING':
          return 'is-info'
        case 'WAITING':
          return 'is-dark'
        case 'UIT':
          return 'is-warning'
        case 'ERROR':
        default:
          return 'is-danger'
      }
    })

    return {
      statusClass
    }
  }
}
</script>

<style lang="scss" scoped>
.status-text {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;

  &.is-success {
    color: #3CAB51;
  }
  &.is-dark {
    color: #4F4F4F;
  }
  &.is-info {
    color: #3B8BE5;
  }
  &.is-warning {
    color: #E5853B;
  }
  &.is-danger {
    color: #E53B3B;
  }
}
</style>
