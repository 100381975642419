<template>
  <div class="per-page">
    <VSelect :value="perPage" @update:value="onChange" :placeholder="perPageText" variant="is-white">
      <option v-for="(text, key) in options" :key="key" :value="key">
        {{ text }}
      </option>
    </VSelect>
  </div>
</template>

<script>
import VSelect from '../Select/VSelect.vue'

export default {
  name: 'VPerPage',
  components: { VSelect },
  emits: ['update:perPage'],
  props: {
    perPage: {
      default: 10,
      type: Number
    },
    perPageText: {
      type: String,
      default: 'Kies een aantal'
    },
    options: {
      type: Object,
      default: () => {
        return {
          10: '10 per pagina',
          20: '20 per pagina',
          50: '50 per pagina',
          100: '100 per pagina'
        }
      }
    }
  },
  setup (props, { emit }) {
    function onChange (v) {
      emit('update:perPage', parseInt(v))
    }

    return {
      onChange
    }
  }
}
</script>

<style lang='scss' scoped>
.per-page {
  width: 200px;
  margin-right: 10px;
}
</style>
