<template>
  <div class="product-options-container">
    <div class="section product-information">
      <VDivider>Product informatie</VDivider>
      <div class="row">
        <div class="column head">EAN</div>
        <div class="column">{{ product.data.EAN }}</div>
      </div>
      <div class="row">
        <div class="column head">Naam</div>
        <div class="column">{{ product.data.name }}</div>
      </div>
      <div class="row">
        <div class="column head">Laatste crawl</div>
        <div class="column">{{ product.data.lastCrawlHuman }}</div>
      </div>
      <div class="row">
        <div class="column head">Prijs status</div>
        <div class="column">
          <div class="price-status">
            <StatusCircle :price-class="product.data.priceClass" :price-text="product.data.priceText" />
            <div class="price-text">{{ product.data.priceText }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column head">Crawl status</div>
        <div class="column">
          <StatusText :status="product.data.crawlStatus" />
        </div>
      </div>
    </div>
    <div class="section product-options">
      <VDivider>Product opties</VDivider>
      <div class="row">
        <div class="column head">Crawlen</div>
        <div class="column">
          <VSwitch
            :initial-value="product.data.enabled"
            :model-value="product.data.enabled"
            @update:modelValue="(v) => toggleEnable(product.id, v)"
          />
        </div>
      </div>
      <div class="row">
        <div class="column head">PriceTiger</div>
        <div class="column">
          <VSwitch
            :initial-value="product.data.automaticPrice"
            :model-value="product.data.automaticPrice"
            @update:modelValue="(v) => updateProduct(product.id, { automaticPrice: v })"
          />
        </div>
      </div>
      <div class="row">
        <div class="column head">Prijs instellingen</div>
        <div class="column">
          <VSwitch
            :initial-value="product.data.customProductPrice"
            :model-value="product.data.customProductPrice"
            @update:modelValue="(v) => updateProduct(product.id, { customProductPrice: v })"
          />
        </div>
      </div>
      <div class="row">
        <div class="column head">Vaste prijs</div>
        <div class="column fixed-price">
          <VSwitch
            :initial-value="product.data.fixedPrice"
            :model-value="product.data.fixedPrice"
            @update:modelValue="(v) => updateProduct(product.id, { fixedPrice: v })"
          />
          <VInput
            v-if="product.data.fixedPrice"
            v-model:value.number="state.currentPrice"
            placeholder="Typ een vaste prijs"
            type="number"
          />
        </div>
      </div>
    </div>
    <div v-if="product.data.customProductPrice" class="section min-price">
      <VDivider>Minimale prijs</VDivider>
      <div class="row input-row">
        <div class="column head">Inkoop prijs</div>
        <div class="column">
          <VInput v-model:value.number="state.purchasePrice" placeholder="Typ de inkoopprijs" type="number" />
        </div>
        <div class="column head">Percentage (%)</div>
        <div class="column">
          <VInput v-model:value.number="state.minPricePercentage" placeholder="Typ een percentage" type="number" />
        </div>
      </div>
      <div class="row input-row">
        <div class="column head">Verzendkosten</div>
        <div class="column">
          <VInput v-model:value.number="state.shippingFee" placeholder="Typ de verzendkosten" type="number" />
        </div>
        <div class="column head">Vast bedrag (+)</div>
        <div class="column">
          <VInput v-model:value.number="state.minPriceSum" placeholder="Typ een prijs" type="number" />
        </div>
      </div>
      <div class="row input-row">
        <div class="column head">Minimale prijs</div>
        <div class="column">
          <VInput readonly placeholder="Automatisch berekend" type="number" :value="state.minPrice" />
          <div class="input-subtext">Inkoopprijs + verzendkosten + (% en +)</div>
        </div>
      </div>
    </div>
    <div v-if="product.data.customProductPrice" class="section max-price">
      <VDivider>Maximale prijs</VDivider>
      <div class="row input-row">
        <div class="column head">Percentage (%)</div>
        <div class="column">
          <VInput v-model:value.number="state.maxPricePercentage" type="number" />
        </div>
        <div class="column head">Vast bedrag (+)</div>
        <div class="column">
          <VInput v-model:value.number="state.maxPriceSum" type="number" />
        </div>
      </div>
      <div class="row input-row">
        <div class="column head">Maximale prijs</div>
        <div class="column">
          <VInput readonly type="number" :value="state.maxPrice" />
          <div class="input-subtext">Minimale prijs + (% en +)</div>
        </div>
      </div>
    </div>
    <div class="section manual-price">
      <VDivider>Site blacklist</VDivider>
      <SiteBlacklist />
    </div>
    <div v-if="debugState.debugVisible" class="product-debug">
      <button @click="debugState.debugVisible = false">HIDE</button>
      <div>
        PRODUCT ID: {{ product.id }}
      </div>
      <pre>
        {{ prettyJson(product.data) }}
      </pre>
    </div>
    <div class="buttons">
      <div v-if="user.email === 'info@dactec.nl'" class="force-button">
        <VButton variant="is-grey-1" @click="debugProduct">Debug product</VButton>
      </div>
      <div class="ok-button">
        <VButton @click="saveFields">Opslaan</VButton>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
import { useToast } from 'vue-toastification'
import VButton from '../ComponentFramework/Button/VButton.vue'
import VDivider from '../ComponentFramework/Divider/VDivider.vue'
import VInput from '../ComponentFramework/Input/VInput.vue'
import VSwitch from '../ComponentFramework/Switch/VSwitch.vue'
import StatusCircle from '../PriceStatus/StatusCircle/StatusCircle.vue'
import SiteBlacklist from '../SiteBlacklist/SiteBlacklist.vue'
import { provideSiteBlacklist } from '../SiteBlacklist/useSiteBlacklist.js'
import StatusText from '../StatusText/StatusText.vue'
import { useUser } from '../User/useUser.js'
import { useProductPrice } from './useProductPrice.js'
import { useProducts } from './useProducts.js'

export default {
  name: 'ProductOptions',
  components: {
    SiteBlacklist,
    VInput,
    VSwitch,
    VDivider,
    VButton,
    StatusText,
    StatusCircle
  },
  props: {
    product: {
      default: () => {
        return {}
      },
      type: Object,
      required: true
    },
    manager: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    }
  },
  setup (props) {
    const { product } = toRefs(props)
    const { user, client: { settings } } = useUser()
    const { sites } = provideSiteBlacklist(product.value.data.siteBlacklist)
    const { success } = useToast()
    const { toggleEnable, updateProduct } = useProducts()

    // TODO THIS DOES NOT WORK!!!!!!!!!!
    // We dont want reactivity going back (mutating an prop object IS reactive)
    const state = reactive(JSON.parse(JSON.stringify(props.product.data)))

    // If there are prop changes, apply them (props.product not reactive inside our state because json stringify)
    watch(props.product, (n) => {
      Object.assign(state, n)
    })

    const debugState = reactive({
      debugVisible: false,
      googleShopping: false,
      googleSearch: false,
      crawler: false
    })

    function debugProduct () {
      debugState.debugVisible = true
    }

    function prettyJson (value) {
      return JSON.stringify(value, null, 2)
    }

    function saveFields () {
      const { maxPrice, minPrice } = useProductPrice(settings, state)
      // eslint-disable-next-line no-unreachable
      updateProduct(product.value.id, {
        siteBlacklist: sites.value,
        purchasePrice: state.purchasePrice,
        minPricePercentage: state.minPricePercentage,
        minPriceSum: state.minPriceSum,
        shippingFee: state.shippingFee,
        maxPricePercentage: state.maxPricePercentage,
        maxPriceSum: state.maxPriceSum,
        currentPrice: state.currentPrice,
        minPrice: minPrice(),
        maxPrice: maxPrice()
      })
      success('Product bijgewerkt!')
      props.manager.close()
    }

    return {
      toggleEnable,
      saveFields,
      updateProduct,
      state,
      debugState,
      debugProduct,
      prettyJson,
      user
    }
  }
}
</script>

<style lang="scss" scoped>
.product-options-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  position: relative;

  .product-debug {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    pre {
      height: 100%;
      overflow-y: auto;
      padding-bottom: 50px;
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    margin-top: auto;
    .cancel-button, .force-button {
      margin-right: 10px;
    }
    .ok-button {
      margin-left: auto;
    }
  }

  .section:not(:first-child) {
    margin-top: 15px;
  }

  .price-status {
    display: flex;
    flex-direction: row;

    .price-text {
      margin-left: 10px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 30px;
    margin-bottom: 8px;

    &.input-row {
      margin-bottom: 10px;
    }

    .column {
      font-size: 14px;
      color: #4F4F4F;

      .input-wrapper {
        display: flex;
      }

      .input-subtext {
        color: #C7C7C7;
        font-size: 12px;
        margin-top: 3px;
      }

      &.head {
        width: 135px;
        font-weight: bold;

        &:not(:first-child) {
          margin-left: 25px;
        }
      }

      &.competitor-price {
        display: flex;
        flex-direction: row;
        align-items: center;

        .price {
          margin-right: 10px;

          .details {
            font-size: 12px;
            color: #B0B0B0;
          }
        }
      }

      &.auto-price {
        font-weight: bold;
        color: #E53B3B;

        &.is-automatic {
          color: #3CAB51;
        }
      }

      &.fixed-price {
        display: flex;
        .input-wrapper {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
