import { ref, provide, inject, computed } from 'vue'

const SortableKey = Symbol('Sortable')

function createSortable (array) {
  const sort = ref(null)
  const sortDirection = ref('ASC')

  function toggleSortDirection () {
    sortDirection.value = sortDirection.value === 'DESC' ? 'ASC' : 'DESC'
  }

  function sortingEnabled () {
    return (sort.value)
  }

  const sortedArray = computed(() => {
    let result = [...array.value]
    if (sortingEnabled()) {
      result = result.sort((a, b) => {
        if (sort.value === 'crawlStatus') {
          if (a.data[sort.value] < b.data[sort.value]) {
            return -1
          }
          if (a.data[sort.value] > b.data[sort.value]) {
            return 1
          }
          return 0
        }

        return a.data[sort.value] - b.data[sort.value]
      })
    }
    if (sortDirection.value === 'DESC') {
      return result.reverse()
    }
    return result
  })

  return {
    toggleSortDirection, sortingEnabled, sortedArray, sort, sortDirection
  }
}

export function provideSortable () {
  const instance = createSortable(...arguments)
  provide(SortableKey, instance)
  return instance
}

export function useSortable () {
  const instance = inject(SortableKey)

  if (!instance) {
    throw new Error('Run provideSortable before useSortable.')
  }

  return instance
}
