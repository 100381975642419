import { computed } from 'vue'

export function usePriceStatus (currentPrice = 0, maxPrice = 0, minPrice = 0, lowestCompetitorPrice = 0) {
  const PRICE_UNKNOWN = 0
  const PRICE_COMPETITOR_LOWER = 1
  const PRICE_AT_MINIMAL = 2
  const PRICE_IN_BETWEEN = 3
  const PRICE_AT_MAXIMUM = 4
  const PRICE_LOWER_THEN_MINIMAL = 5

  const priceStatus = computed(() => {
    if (lowestCompetitorPrice === 0) {
      return PRICE_UNKNOWN
    }

    if (currentPrice < minPrice) {
      return PRICE_LOWER_THEN_MINIMAL
    }
    if (lowestCompetitorPrice < minPrice) {
      return PRICE_COMPETITOR_LOWER
    }
    if (currentPrice === minPrice) {
      return PRICE_AT_MINIMAL
    }
    if (currentPrice > minPrice && currentPrice < maxPrice) {
      return PRICE_IN_BETWEEN
    }
    if (currentPrice === maxPrice) {
      return PRICE_AT_MAXIMUM
    }
    return PRICE_UNKNOWN
  })

  const priceClass = computed(() => {
    switch (priceStatus.value) {
      case PRICE_LOWER_THEN_MINIMAL: return 'is-purple'
      case PRICE_COMPETITOR_LOWER: return 'is-danger'
      case PRICE_AT_MINIMAL: return 'is-warning'
      case PRICE_IN_BETWEEN: return 'is-info'
      case PRICE_AT_MAXIMUM: return 'is-success'
      case PRICE_UNKNOWN:
      default: return 'is-off'
    }
  })

  const priceText = computed(() => {
    switch (priceStatus.value) {
      case PRICE_LOWER_THEN_MINIMAL: return 'Huidige prijs is lager dan minimale prijs'
      case PRICE_COMPETITOR_LOWER: return 'Prijs concurrent is onder de minimale prijs'
      case PRICE_AT_MINIMAL: return 'Prijs staat op minimaal'
      case PRICE_IN_BETWEEN: return 'Prijs zit tussen minimaal/maximaal'
      case PRICE_AT_MAXIMUM: return 'Prijs staat op maximaal'
      case PRICE_UNKNOWN:
      default: return 'Niet gemeten'
    }
  })

  const priceStatusOrder = computed(() => {
    switch (priceStatus.value) {
      case PRICE_LOWER_THEN_MINIMAL: return 1
      case PRICE_COMPETITOR_LOWER: return 2
      case PRICE_AT_MINIMAL: return 3
      case PRICE_IN_BETWEEN: return 4
      case PRICE_AT_MAXIMUM: return 5
      case PRICE_UNKNOWN:
      default: return 6
    }
  })

  return {
    priceStatus,
    priceClass,
    priceStatusOrder,
    priceText,
    PRICE_UNKNOWN,
    PRICE_COMPETITOR_LOWER,
    PRICE_AT_MINIMAL,
    PRICE_IN_BETWEEN,
    PRICE_AT_MAXIMUM
  }
}
