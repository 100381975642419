<template>
  <div class="view-product-container">
    <div class="product-information">
      <VDivider>Product informatie</VDivider>
      <div class="row">
        <div class="column head">EAN</div>
        <div class="column">{{ product.data.EAN }}</div>
      </div>
      <div class="row">
        <div class="column head">Naam</div>
        <div class="column">{{ product.data.name }}</div>
      </div>
      <div class="row">
        <div class="column head">Laatste crawl</div>
        <div class="column">{{ product.data.lastCrawlHuman }}</div>
      </div>
      <div class="row">
        <div class="column head">Prijs status</div>
        <div class="column">
          <div class="price-status">
            <StatusCircle :price-class="product.data.priceClass" :price-text="product.data.priceText" />
            <div class="price-text">{{ product.data.priceText }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column head">Crawl status</div>
        <div class="column">
          <StatusText :status="product.data.crawlStatus" />
        </div>
      </div>
    </div>
    <div class="price-information">
      <VDivider>Prijs</VDivider>
      <div class="row">
        <div class="column head">Prijs concurrent</div>
        <div class="column competitor-price">
          <div class="price">&euro; {{ product.data.lowestPrice.toFixed(2) }} <span class="details">(laagste)</span></div>
          <VButton @click="viewPriceResult">Alle prijzen bekijken</VButton>
        </div>
      </div>
      <div class="row">
        <div class="column head">Huidige prijs</div>
        <div class="column">&euro; {{ product.data.currentPrice.toFixed(2) }}</div>
      </div>
      <div class="row">
        <div class="column head">Inkoop prijs</div>
        <div class="column">&euro; {{ product.data.purchasePrice.toFixed(2) }}</div>
      </div>
      <div class="row">
        <div class="column head">Minimale prijs</div>
        <div class="column">&euro; {{ product.data.minPrice.toFixed(2) }}</div>
      </div>
      <div class="row">
        <div class="column head">Maximale prijs</div>
        <div class="column">&euro; {{ product.data.maxPrice.toFixed(2) }}</div>
      </div>
    </div>
    <div class="options-information">
      <VDivider>Product opties</VDivider>
      <div class="row">
        <div class="column head">Automatische prijs</div>
        <div class="column auto-price" :class="{'is-automatic': product.data.automaticPrice}">{{ product.data.automaticPrice ? 'AAN' : 'UIT' }}</div>
      </div>
      <div class="row">
        <div class="column head">Prijs berekening</div>
        <div class="column">Product 1</div>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '../ComponentFramework/Button/VButton.vue'
import VDivider from '../ComponentFramework/Divider/VDivider.vue'
import StatusCircle from '../PriceStatus/StatusCircle/StatusCircle.vue'
import StatusText from '../StatusText/StatusText.vue'
import ViewProductPriceResult from './ViewProductPriceResult.vue'

export default {
  name: 'ViewProduct',
  components: { VDivider, VButton, StatusText, StatusCircle },
  props: {
    product: {
      default: () => {
        return {}
      },
      type: Object,
      required: true
    },
    manager: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    }
  },
  setup (props) {
    function viewPriceResult () {
      props.manager.open(ViewProductPriceResult, {
        title: `${props.product.data.name} - Prijzen resultaat`,
        product: props.product
      })
    }

    return {
      viewPriceResult
    }
  }
}
</script>

<style lang="scss" scoped>
.view-product-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;

  .price-status {
    display: flex;
    flex-direction: row;
    .price-text {
      margin-left: 10px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    margin-bottom: 8px;
    .column {
      font-size: 14px;
      color: #4F4F4F;

      &.head {
        width: 135px;
        font-weight: bold;
      }

      &.competitor-price {
        display: flex;
        flex-direction: row;
        align-items: center;
        .price {
          margin-right: 10px;
          .details {
            font-size: 12px;
            color: #B0B0B0;
          }
        }
      }

      &.auto-price {
        font-weight: bold;
        color: #E53B3B;

        &.is-automatic {
          color: #3CAB51;
        }
      }
    }
  }
}
</style>
