<template>
  <tr class="product-item">
    <td>
      <input @change="e => $emit('select', { e, product })" type="checkbox" :checked="product.checked" />
    </td>
    <td>
      <div>{{ product.data.EAN }}</div>
    </td>
    <td>
      <div class="product-name" :title="product.data.name">{{ product.data.name }}</div>
    </td>
    <td>
      <div>{{ product.data.lastCrawlHuman }}</div>
    </td>
    <td>
      <div>
        <StatusCircle :price-text="product.data.priceText" :price-class="product.data.priceClass" />
      </div>
    </td>
    <td>
      <StatusText :status="product.data.crawlStatus" />
    </td>
    <td>
      <div class="current-price">
        <div>&euro; {{ product.data.currentPrice.toFixed(2) }}</div>
        <font-awesome-icon class="icon" v-if="product.data.fixedPrice" icon="hand-paper" />
      </div>
    </td>
    <td><div>&euro; {{ product.data.purchasePrice.toFixed(2) }}</div></td>
    <td><div>&euro; {{ product.data.minPrice.toFixed(2) }}</div></td>
    <td><div>&euro; {{ product.data.maxPrice.toFixed(2) }}</div></td>
    <td><div>&euro; {{ product.data.lowestPrice.toFixed(2) }}</div></td>
    <td><div>{{ product.data.amountCompetitors }}</div></td>
    <td class="is-right no-padding-top">
      <div class="actions">
        <div class="switch">
          <VSwitch
            :initial-value="product.data.enabled"
            :model-value="product.data.enabled"
            @update:modelValue="(v) => toggleEnable(product.id, v)"
          />
        </div>
        <div @click="() => viewProduct(product)" class="view">
          <font-awesome-icon icon="eye" />
        </div>
        <div @click="() => productOptions(product)" class="settings">
          <font-awesome-icon icon="cog" />
        </div>
        <div @click="() => deleteProduct(product.id)" class="delete">
          <font-awesome-icon icon="trash" />
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { useModal } from '../ComponentFramework/Modal/Modal.js'
import VSwitch from '../ComponentFramework/Switch/VSwitch.vue'
import StatusCircle from '../PriceStatus/StatusCircle/StatusCircle.vue'
import StatusText from '../StatusText/StatusText.vue'
import ProductOptions from './ProductOptions.vue'
import { useProducts } from './useProducts.js'
import ViewProduct from './ViewProduct.vue'

export default {
  name: 'ProductItem',
  emits: ['select'],
  components: { StatusCircle, StatusText, VSwitch },
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup () {
    const modal = useModal()
    const { deleteProduct, toggleEnable } = useProducts()

    function viewProduct (product) {
      modal.open(ViewProduct, { title: product.data.name, product })
    }

    function productOptions (product) {
      modal.open(ProductOptions, {
        title: `${product.data.name} - Opties`,
        product,
        onCancel: () => {
          if (confirm('Weet je zeker dat je niet wilt opslaan?')) {
            modal.close()
          }
        }
      })
    }

    return {
      viewProduct,
      productOptions,
      deleteProduct,
      toggleEnable
    }
  }
}
</script>

<style lang="scss" scoped>
.view, .settings, .delete {
  cursor: pointer;
}
.product-item .product-name {
  display: block;
  width: 350px;
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.current-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    margin-left: 10px;
  }
}
</style>
