<template>
  <div class='settings-container'>
    <div class="title">Instellingen</div>
    <div class="subtitle">Globale instellingen voor de crawler of alle producten</div>
    <div class="section first">
      <VSpacer text="Crawler instellingen" />
      <VField label-left="Aantal keer crawlen">
        <VSelect v-model:value="settings.crawlPerDay" placeholder="Kies een aantal">
          <option :value="1">1x 12 uur</option>
          <option :value="2">1x per dag</option>
          <option :value="3">1x per week</option>
          <option :value="4">1x per maand</option>
        </VSelect>
      </VField>
      <VField label-left="Maximaal aantal producten per crawl">
        <VSelect v-model:value="settings.maxProductsPerCrawl" placeholder="Kies een aantal">
          <option :value="120">Slow (120 producten per uur)</option>
          <option :value="240">Standaard (240 producten per uur)</option>
          <option :value="360">Warp (360 producten per uur)</option>
          <option :value="720">Mega (720 producten per uur)</option>
          <option :value="1440">Ultra (1440 producten per uur)</option>
          <option :value="5184000">Unlimited (Unlimited producten per uur)</option>
        </VSelect>
      </VField>
    </div>
    <div class="section">
      <VSpacer text="Sites uitsluiten" />
      <div class="has-padding-left">
        <SiteBlacklist />
      </div>
    </div>
    <div class="section">
      <VSpacer text="Domein extensies uitsluiten" />
      <div class="has-padding-left">
        <ExtensionWhitelist />
      </div>
    </div>
    <div class="section">
      <VSpacer text="Wordpress instellingen" />
      <VField label-left="Wordpress url">
        <VInput v-model:value="settings.woocommerce3.url" placeholder="https://schoenspecialist.nl/" />
      </VField>
      <VField label-left="Wooocommerce sleutel">
        <VInput v-model:value="settings.woocommerce3.key" placeholder="ck_jxya6__EXAMPLE__5qjc8" />
      </VField>
      <VField label-left="Wooocommerce geheim">
        <VInput type="password" v-model:value="settings.woocommerce3.secret" placeholder="cs_sxr1__EXAMPLE__47b5z" />
      </VField>
    </div>
    <div class="section">
      <VSpacer text="Globale prijs marges" />
      <div class="section min-price has-padding-left">
        <VDivider>Minimale prijs</VDivider>
        <div class="row input-row">
          <div class="column head">Percentage (%)</div>
          <div class="column">
            <VInput v-model:value.number="settings.minPricePercentage" placeholder="Typ een percentage" type="number" />
          </div>
          <div class="column head">Verzendkosten</div>
          <div class="column">
            <VInput v-model:value.number="settings.shippingFee" placeholder="Typ de verzendkosten" type="number" />
          </div>
        </div>
        <div class="row input-row">
          <div class="column head">Vast bedrag (+)</div>
          <div class="column">
            <VInput v-model:value.number="settings.minPriceSum" placeholder="Typ een prijs" type="number" />
          </div>
        </div>
      </div>
      <div class="section max-price has-padding-left">
        <VDivider>Maximale prijs</VDivider>
        <div class="row input-row">
          <div class="column head">Percentage (%)</div>
          <div class="column">
            <VInput v-model:value.number="settings.maxPricePercentage" type="number" placeholder="Typ een percentage" />
          </div>
          <div class="column head">Vast bedrag (+)</div>
          <div class="column">
            <VInput v-model:value.number="settings.maxPriceSum" type="number" placeholder="Typ een bedrag" />
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <div class="ok-button">
        <VButton @click="update">Opslaan</VButton>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue'
import { useToast } from 'vue-toastification'
import ExtensionWhitelist from '../ExtensionWhitelist/ExtensionWhitelist.vue'
import { provideExtensionWhitelist } from '../ExtensionWhitelist/useExtensionWhitelist.js'
import { useUser } from '../User/useUser.js'
import VButton from '../ComponentFramework/Button/VButton.vue'
import VDivider from '../ComponentFramework/Divider/VDivider.vue'
import VField from '../ComponentFramework/Field/VField.vue'
import VInput from '../ComponentFramework/Input/VInput.vue'
import VSelect from '../ComponentFramework/Select/VSelect.vue'
import VSpacer from '../ComponentFramework/Spacer/VSpacer.vue'
import SiteBlacklist from '../SiteBlacklist/SiteBlacklist.vue'
import { provideSiteBlacklist } from '../SiteBlacklist/useSiteBlacklist.js'

export default {
  name: 'Settings',
  components: { ExtensionWhitelist, VDivider, VInput, VField, VSelect, VButton, SiteBlacklist, VSpacer },
  setup () {
    const { clientDoc, client } = useUser()
    const { success } = useToast()
    const { siteBlacklist, extensionWhitelist } = toRefs(client.settings)
    const { sites } = provideSiteBlacklist(siteBlacklist)
    const { extensions } = provideExtensionWhitelist(extensionWhitelist)

    function update () {
      clientDoc.value.ref.update({
        settings: {
          minPricePercentage: client.settings.minPricePercentage,
          minPriceSum: client.settings.minPriceSum,
          shippingFee: client.settings.shippingFee,
          maxPricePercentage: client.settings.maxPricePercentage,
          maxPriceSum: client.settings.maxPriceSum,
          siteBlacklist: sites.value,
          extensionWhitelist: extensions.value,
          crawlPerDay: client.settings.crawlPerDay,
          woocommerce3: client.settings.woocommerce3,
          maxProductsPerCrawl: client.settings.maxProductsPerCrawl
        }
      })
      success('Instellingen opgeslagen!')
    }

    return {
      update,
      settings: client.settings
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 18px 20px;

  .has-padding-left {
    padding-left: 15px;
  }

  .section {
    max-width: 650px;
    margin-bottom: 50px;
    &.first {
      margin-top: 28px;
    }
    .spacer-container {
      margin-bottom: 20px;
    }
  }

  .buttons {
    display: flex;
    width: 100%;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 30px;
    margin-bottom: 8px;

    &.input-row {
      margin-bottom: 10px;
    }

    .column {
      font-size: 14px;
      color: #4F4F4F;

      .input-wrapper {
        display: flex;
      }

      .input-subtext {
        color: #C7C7C7;
        font-size: 12px;
        margin-top: 3px;
      }

      &.head {
        width: 135px;
        font-weight: bold;

        &:not(:first-child) {
          margin-left: 25px;
        }
      }

      &.competitor-price {
        display: flex;
        flex-direction: row;
        align-items: center;

        .price {
          margin-right: 10px;

          .details {
            font-size: 12px;
            color: #B0B0B0;
          }
        }
      }

      &.auto-price {
        font-weight: bold;
        color: #E53B3B;

        &.is-automatic {
          color: #3CAB51;
        }
      }
    }
  }
}
</style>
