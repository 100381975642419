<template>
  <div class="product-price-result-container">
    <div class="price-overview">
      <VDivider>Prijs overzicht</VDivider>
      <div class="price-result">
        <div class="row">
          <div class="column is-heading">Hostnaam</div>
          <div class="column is-heading">Prijs</div>
        </div>
        <div v-if="latestCrawl.length === 0" class="row">
          Geen resultaten
        </div>
        <div v-for="(results, shop) in latestCrawl" :key="shop" class="row">
          <div class="results">
            <span class="shop-name">{{ shop }}</span>
            <div v-for="(result, index) in results" :key="result.url" class="result">
              <div class="result-column">
                Resultaat {{ index + 1 }}
                <a :title="result.url" :href="result.url" target="_blank">
                  <font-awesome-icon class="icon" :icon="['far', 'external-link']" />
                </a>
              </div>
              <div class="result-column">&euro; {{ result.price }}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <VDivider class="no-margins"></VDivider>
        </div>
        <div class="row">
          <div class="column">Jouw webshop</div>
          <div class="column">&euro; {{ product.data.currentPrice.toFixed(2) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import VDivider from '../ComponentFramework/Divider/VDivider.vue'
import { useProducts } from './useProducts.js'
export default {
  name: 'ViewProductPriceResult',
  components: { VDivider },
  props: {
    product: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  setup (props) {
    const { findProduct } = useProducts()
    const latestCrawl = ref({})

    async function fetchLatestCrawl () {
      const product = await findProduct(props.product.id)
      const resultRef = await product.ref.collection('crawlResults')
        .orderBy('finishedAt', 'desc')
        .limit(1)
        .get()

      if (resultRef.size === 0) return

      const latest = resultRef.docs[0].data()
      latestCrawl.value = transform(latest.successResult)
    }

    function transform (result) {
      const transformed = {}
      for (const url in result) {
        if (!Object.prototype.hasOwnProperty.call(result, url)) continue
        const data = result[url]
        const parsedUrl = new URL(url)
        let name = parsedUrl.hostname

        if (data.seller && data.seller.name) {
          name += ` - ${data.seller.name}`
        }

        if (!transformed[name]) {
          transformed[name] = []
        }

        if (!data.price) {
          console.log(url, data)
        }

        transformed[name].push({
          name,
          url,
          price: data ? data.price.toFixed(2) : '-,--'
        })
      }

      return transformed
    }

    fetchLatestCrawl()

    return {
      latestCrawl
    }
  }
}
</script>

<style lang="scss" scoped>
.product-price-result-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;

  .refresh-button {
    display: inline-flex;
  }

  .price-result {
    margin-top: 15px;

    .results {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: 14px;

      .shop-name {
        font-weight: bold;
      }

      .result {
        display: flex;
        margin-top: 5px;

        .result-column {
          display: flex;

          .icon {
            margin-left: 5px;
            cursor: pointer;
          }

          &:first-child {
            width: 400px;
          }
        }
      }
    }

    .row {
      color: #4F4F4F;
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;

      .column {
        display: flex;
        align-items: center;
        font-size: 14px;

        &:first-child {
          width: 400px;
        }

        &.is-heading {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
