import { computed, ref } from 'vue'

export function usePaginate (arrayToPaginate) {
  const currentPage = ref(1)
  const perPage = ref(10)

  const totalPages = computed(() => {
    const total = arrayToPaginate.value.length
    if (total === 0) return 1
    return Math.ceil(total / perPage.value)
  })

  const paginatedResult = computed(() => {
    return arrayToPaginate.value.slice((currentPage.value - 1) * perPage.value, currentPage.value * perPage.value)
  })

  return {
    currentPage,
    perPage,
    totalPages,
    paginatedResult
  }
}
