<template>
  <div class="button-container" :class="[{'is-loading': loading, 'is-disabled': disabled}, $attrs.class]">
    <button v-bind="$attrs" :type="type" class="v-button" :class="[variant, {'has-icon-left': iconLeft, 'is-rounded': rounded, 'is-loading': loading}]">
      <font-awesome-icon v-if="iconLeft" :icon="iconLeft" class="icon left" />
      <span class="text">
        <slot />
      </span>
      <font-awesome-icon v-if="loading" class="v-loader" spin icon="spinner" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'VButton',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'button'
    },
    variant: {
      type: String,
      default: 'is-primary'
    },
    loading: {
      type: Boolean
    },
    iconLeft: {
      type: [Boolean, String, Array],
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang='scss' scoped>
  .button-container {
    &.is-loading {
      cursor: wait;
      .v-button {
        pointer-events: none;
        .icon, .text {
          color: transparent;
        }
        .v-loader {
          position: absolute;
          left: calc(50% - .5em);
          top: calc(50% - .5em);
          animation-duration: 1s;
        }
      }
    }
    &.is-disabled {
      cursor: not-allowed;
      .v-button {
        pointer-events: none;
        opacity: 0.5;
      }
    }
    .v-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      width: 100%;
      height: 32px;
      line-height: 32px;
      cursor: pointer;
      transition: background-color ease 100ms;
      user-select: none;
      font-size: 12px;
      border-radius: 3px;
      position: relative;
      outline: none;
      border: none;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 0.2px;

      &.has-icon-left {
        padding: 10px;
      }

      &.is-rounded {
        border-radius: 20px;
      }

      &.is-loading {
        justify-content: center;
      }

      .v-loader {
        font-size: 16px;
      }

      .icon {
        font-size: 16px;
        &.left {
          margin-right: 5px;
        }
      }

      &.is-primary {
        background-color: $primary;
        color: var(--text-invert);
        font-weight: bold;
        &:hover {
          background-color: darken($primary, 10%);
        }
        &:active {
          background-color: darken($primary, 15%);
        }
      }

      &.is-secondary {
        background-color: $secondary;
        color: var(--text-invert);
        font-weight: bold;
        &:hover {
          background-color: darken($secondary, 10%);
        }
        &:active {
          background-color: darken($secondary, 15%);
        }
      }

      &.is-danger {
        background-color: $danger;
        color: var(--text-invert);
        font-weight: bold;
        &:hover {
          background-color: darken($danger, 10%);
        }
        &:active {
          background-color: darken($danger, 15%);
        }
      }

      &.is-grey-1 {
        background-color: var(--grey-3);
        color: var(--text-invert);
        font-weight: bold;
        &:hover {
          background-color: var(--grey-2);
        }
        &:active {
          background-color: var(--grey-2);
        }
      }

      &.is-light {
        background-color: var(--input-background);
        color: $primary;
        &:hover {
          background-color: var(--input-background-hover);
        }
        &:active {
          background-color: var(--input-background-active);
        }
      }
    }
  }
</style>
