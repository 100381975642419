<template>
  <div class="row input-row">
    <div class="column head">Typ een domein</div>
    <div class="column blacklist-input">
      <VInput @keydown.enter="add" v-model:value="input" placeholder="Bijv 'schoenspecialist.nl'" />
      <VButton :disabled="!input" @click="add">Toevoegen</VButton>
    </div>
  </div>
  <div class="blacklisted-sites">
    <div v-for="(site, idx) in sites" :key="site" class="blacklisted-site">
      <a class="link" :href="'https://' + site">{{ site }}</a>
      <font-awesome-icon class="icon" @click="() => remove(idx)" icon="trash" />
    </div>
  </div>
</template>

<script>
import VButton from '../ComponentFramework/Button/VButton.vue'
import VInput from '../ComponentFramework/Input/VInput.vue'
import { useSiteBlacklist } from './useSiteBlacklist.js'

export default {
  name: 'SiteBlacklist',
  components: { VButton, VInput },
  setup () {
    const { input, add, remove, sites } = useSiteBlacklist()

    return {
      sites,
      input,
      add,
      remove
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 30px;
  margin-bottom: 8px;

  &.input-row {
    margin-bottom: 10px;
  }

  .column {
    font-size: 14px;
    color: #4F4F4F;

    .input-wrapper {
      display: flex;
    }

    &.head {
      width: 170px;
      font-weight: bold;

      &:not(:first-child) {
        margin-left: 25px;
      }
    }

    &.blacklist-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;

      .input-wrapper {
        margin-right: 10px;
        &::v-deep(.input) {
          width: 100%;
        }
      }
    }
  }
}
.blacklisted-sites {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.blacklisted-site {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 5px;

  .link {
    color: #3B8BE5;
    font-size: 12px;
    margin-right: 5px;
  }

  .icon {
    font-size: 10px;
    color: #979797;
    cursor: pointer;
  }
}
</style>
