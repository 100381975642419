<template>
  <div class="products-container">
    <div class="header">
      <div class="text">
        <div class="title">Producten overzicht</div>
        <div class="subtitle">Alle producten met een EAN nummer</div>
      </div>
      <div class="search">
        <VField label-left="Filteren" layout="is-column" :has-margin="false">
          <VSelect v-model:value="selectedFilter" class="filter" variant="is-white" placeholder="Kies een filter">
            <option :value="false">Geen filter</option>
            <option value="nocomp">Geen concurrentie</option>
            <option value="comphighermax">Conc. prijs hoger dan max prijs</option>
            <option value="notsold7">Afgelopen 7 dagen niet verkocht</option>
            <option disabled>Afgelopen 14 dagen niet verkocht</option>
            <option value="stock">Producten op voorraad</option>
            <option value="nostock">Producten niet op voorraad</option>
            <option value="fixedprice">Producten met handmatige prijs</option>
          </VSelect>
        </VField>
        <VField label-left="Zoeken" layout="is-column" :has-margin="false">
          <VInput @update:value="debounce" :value="null" placeholder="Typ een EAN of productnaam" variant="is-white" />
        </VField>
      </div>
    </div>
    <div class="paginate-controls">
      <VButton
        v-if="selectedProducts.length !== 0"
        class="multi-edit-button"
        @click="openMultiProductEditor"
      >
        {{ selectedProducts.length }} producten bewerken
      </VButton>
      <VPerPage
        v-model:perPage="perPage"
      />
      <VPaginator
        :loading="loading"
        :total-pages="totalPages"
        v-model:page="currentPage"
      />
    </div>
    <VTable @selectAll="selectAllProducts" :allSelected="allSelected">
      <ProductItem v-for="(product) in paginatedResult" @select="selectProduct" :key="product.id" :product="product" />
    </VTable>
    <transition name="fade">
      <VModal v-if="visible" />
    </transition>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import VButton from '../ComponentFramework/Button/VButton.vue'
import VField from '../ComponentFramework/Field/VField.vue'
import VInput from '../ComponentFramework/Input/VInput.vue'
import { provideModal } from '../ComponentFramework/Modal/Modal.js'
import VModal from '../ComponentFramework/Modal/VModal.vue'
import VPaginator from '../ComponentFramework/Paginator/VPaginator.vue'
import VPerPage from '../ComponentFramework/Paginator/VPerPage.vue'
import VSelect from '../ComponentFramework/Select/VSelect.vue'
import { provideSortable } from '../ComponentFramework/Table/useSortable.js'
import VTable from '../ComponentFramework/Table/VTable.vue'
import useDebounce from '../Debounce/useDebounce.js'
import ProductItem from './ProductItem.vue'
import ProductsEditMultiple from './ProductsEditMultiple.vue'
import { useFilter } from './useFilter.js'
import { useSearch } from './useSearch.js'
import { usePaginate } from './usePaginate.js'
import { provideProducts } from './useProducts.js'
import { useProductSelect } from './useProductSelect.js'

export default {
  name: 'Products',
  components: { VSelect, VButton, ProductItem, VPerPage, VPaginator, VField, VInput, VModal, VTable },
  setup () {
    const selectedFilter = ref(false)
    const { debouncedValue, debounce } = useDebounce()
    const { products, loading, getProductIndex } = provideProducts()
    const { filteredResult } = useFilter(products, selectedFilter)
    const { searchResult } = useSearch(filteredResult, debouncedValue)
    const { sortedArray } = provideSortable(searchResult)
    const { paginatedResult, totalPages, currentPage, perPage } = usePaginate(sortedArray)
    const modal = provideModal()
    const { allSelected, selectedProducts, selectAllProducts, selectProduct, deselectAll } = useProductSelect(getProductIndex, products, paginatedResult, loading)

    watch(debouncedValue, () => {
      currentPage.value = 1
    })

    function openMultiProductEditor () {
      modal.open(ProductsEditMultiple, {
        title: 'Producten bewerken',
        selectedProducts,
        onOk: () => {
          deselectAll()
        }
      })
    }

    return {
      selectedFilter,
      openMultiProductEditor,
      allSelected,
      selectedProducts,
      selectAllProducts,
      debounce,
      selectProduct,
      visible: modal.visible,
      loading,
      paginatedResult,
      totalPages,
      currentPage,
      perPage
    }
  }
}
</script>

<style lang="scss" scoped>
.products-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 18px 20px;
  overflow-y: auto;
  margin-right: 20px;

  .paginate-controls {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-top: 20px;
    .per-page, .multi-edit-button {
      margin-right: 10px;
    }
  }

  .header {
    display: flex;
    .search {
      width: 500px;
      margin-left: auto;
      display: flex;
      align-items: center;
      .select-container {
        margin-right: 10px;
      }
      &::v-deep(.field) {
        margin-top: auto;
      }
    }
  }
}
</style>
