<template>
  <div class="field" :class="[layout, {'is-danger': errorMessage, 'has-label-left': labelLeft, 'has-margin-bottom': hasMargin}]">
    <label v-if="labelLeft" class="v-label">
      <span v-if="required" class="required">*</span>
      {{ labelLeft }}
    </label>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VField',
  props: {
    layout: {
      type: String,
      default: 'is-row',
      validator: (v) => {
        return ['is-row', 'is-column'].includes(v)
      }
    },
    labelLeft: {
      type: [String, Boolean],
      default: false
    },
    errorMessage: {
      type: [String, Boolean],
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    hasMargin: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang='scss' scoped>
.field {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 36px;

  &.has-margin-bottom {
    margin-bottom: 20px;
  }

  &.is-row {
    flex-direction: row;
    align-items: center;
    .v-label {
      flex: 2 2 194px;
    }
    &.has-label-left {
      padding-left: 15px;
    }
  }

  &.is-column {
    flex-direction: column;
    justify-content: start;
    .v-label {
      margin-bottom: 3px;
    }
  }

  &::v-deep(.validator-message) {
    margin-top: 3px;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
  }

  @each $name, $color in $vColors {
    &.#{$name} {
      &::v-deep(.input .icon, .select .icon) {
        color: $color;
      }
      &::v-deep(.validator-message) {
        color: $color;
      }
      &::v-deep(.input input, .select select) {
        border: 1px solid $color;
      }

      &.is-row {
        .label {
          color: $color;
        }
      }
    }
  }
}
.help-icon {
  position: absolute;
  right: -18px;
  color: var(--text);
  font-size: 13px;
}
.v-label {
  font-weight: bold;
  font-size: 14px;
  color: var(--text);

  .required {
    color: $danger;
    font-weight: bold;
  }
}
</style>
