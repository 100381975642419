<template>
  <div class="pages">
    <VButton :loading="loading" rounded variant="is-light" @click="currentPage === 1 ? setPage(totalPages) : setPage(currentPage - 1)">
      {{ previousText }}
    </VButton>

    <template v-if="totalPages <= 6">
      <div v-for="number in totalPages" :key="number" class="page" :class="{'is-active': currentPage === number}" @click="setPage(number)">
        {{ number }}
      </div>
    </template>

    <template v-if="totalPages > 6 && currentPage <= 3">
      <div v-for="number in [1, 2, 3, 4, 5]" :key="number" class="page" :class="{'is-active': currentPage === number}" @click="setPage(number)">
        {{ number }}
      </div>
      <div class="page unselectable">
        ...
      </div>
      <div class="page" :class="{'is-active': currentPage === totalPages}" @click="setPage(totalPages)">
        {{ totalPages }}
      </div>
    </template>

    <template v-if="totalPages > 6 && currentPage > totalPages - 3">
      <div class="page" :class="{'is-active': currentPage === 1}" @click="setPage(1)">
        {{ 1 }}
      </div>
      <div class="page unselectable">
        ...
      </div>
      <div v-for="number in [4, 3, 2, 1, 0]" :key="totalPages - number" class="page" :class="{'is-active': currentPage === totalPages - number}" @click="setPage(totalPages - number)">
        {{ totalPages - number }}
      </div>
    </template>

    <template v-if="totalPages > 6 && currentPage <= totalPages - 3 && currentPage > 3">
      <div class="page" :class="{'is-active': currentPage === 1}" @click="setPage(1)">
        {{ 1 }}
      </div>
      <div class="page unselectable">
        ...
      </div>
      <div class="page" :class="{'is-active': currentPage === currentPage - 1}" @click="setPage(currentPage - 1)">
        {{ currentPage - 1 }}
      </div>
      <div class="page is-active">
        {{ currentPage }}
      </div>
      <div class="page" :class="{'is-active': currentPage === currentPage + 1}" @click="setPage(currentPage + 1)">
        {{ currentPage + 1 }}
      </div>
      <div class="page unselectable">
        ...
      </div>
      <div class="page" :class="{'is-active': currentPage === totalPages}" @click="setPage(totalPages)">
        {{ totalPages }}
      </div>
    </template>

    <VButton :loading="loading" rounded variant="is-light" @click="currentPage === totalPages ? setPage(1) : setPage(currentPage + 1)">
      {{ nextText }}
    </VButton>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import VButton from '../Button/VButton.vue'

export default {
  name: 'VPaginator',
  components: { VButton },
  props: {
    totalPages: {
      type: Number,
      default: 1
    },
    page: {
      type: Number,
      default: 1
    },
    previousText: {
      type: String,
      default: 'Previous'
    },
    nextText: {
      type: String,
      default: 'Next'
    },
    loading: Boolean
  },
  emits: ['update:page'],
  setup (props, { emit }) {
    const currentPage = ref(props.page)

    watch(() => props.page, () => (currentPage.value = props.page))

    function setPage (number) {
      if (props.loading) return
      emit('update:page', number)
    }

    return { setPage, currentPage }
  }
}
</script>

<style lang='scss' scoped>
  .pages {
    display: flex;
    height: 100%;
    margin-right: 10px;
    .button-container {
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
    .page {
      color: var(--text);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      min-width: 32px;
      min-height: 32px;
      cursor: pointer;
      font-size: 14px;
      user-select: none;
      &:hover:not(.unselectable) {
        background-color: $primary;
        color: var(--text-invert);
      }
      &.is-active:not(.unselectable) {
        color: var(--text-invert);
        background-color: $primary;
      }
    }
  }
</style>
