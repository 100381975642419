<template>
  <transition name="fade">
    <Loader v-if="loading" />
  </transition>
  <transition name="slide-in">
    <Navbar v-if='!loading && loggedIn' />
  </transition>
  <router-view v-if="!loading" v-slot="{ Component }">
    <transition mode="out-in" name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import { ref } from 'vue'
import firebase from '@/./firebase.js'
import { useRoute, useRouter } from 'vue-router'
import Loader from './features/Loader/Loader.vue'
import { provideLoader } from './features/Loader/useLoader.js'
import { provideUser } from './features/User/useUser.js'
import Navbar from './features/ComponentFramework/Navbar/Navbar.vue'

export default {
  name: 'App',
  components: { Loader, Navbar },
  setup () {
    const { setUser } = provideUser()
    const loggedIn = ref(false)
    const { loading, hide: hideLoader, show: showLoader } = provideLoader()
    const router = useRouter()
    const route = useRoute()

    firebase.auth().onAuthStateChanged(user => {
      showLoader()
      loggedIn.value = !!user

      if (!loggedIn.value) {
        router.push({ name: 'login' })
        hideLoader()
        return
      }

      setUser(user).then(() => {
        hideLoader()
        if (route.name === 'login') {
          router.push({ name: 'products' })
        }
      })
    })

    return {
      loggedIn,
      loading
    }
  }
}
</script>

<style lang='scss' src='@/styles/Reset.scss'></style>

<style lang='scss'>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  background: var(--background);
  --background: #{$light-background};
  --background-dark: #{$light-background-dark};
  --text: #{$light-text};
  --text-2: #{$light-text-2};
  --text-invert: #{$dark-text};
  --grey-1: #{$light-grey-1};
  --grey-2: #{$light-grey-2};
  --grey-3: #{$light-grey-3};

  --input-background: #{$light-input-background};
  --input-background-hover: #{$light-input-background-hover};
  --input-background-active: #{$light-input-background-active};
  --input-text: #{$light-input-text};
  --input-placeholder: #{$light-input-placeholder};
  --spacer-bg: #{$light-spacer-bg};
  --background-modal: #FFFFFF;
}

//@import '~bulma/css/bulma.css';
  html, body, #app {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
    position: relative;
    display: flex;
  }

.title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 5px;
  color: $light-text;
}

.subtitle {
  font-size: 14px;
  color: $light-text;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-in-enter-active,
.slide-in-leave-active {
  animation: slide-in 250ms forwards;
}

.slide-in-enter-from,
.slide-in-leave-to {
  transform: translateX(-100%);
}
</style>
