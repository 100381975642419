import { computed, inject, provide, ref } from 'vue'

const SiteBlackListKey = Symbol('SiteBlackListKey')

export function createExtensionWhitelist (currentExtensionWhitelist = []) {
  console.log(currentExtensionWhitelist)
  const input = ref(null)
  const extensions = ref(currentExtensionWhitelist || [])

  const availableExtensions = ['.nl', '.com', '.be', '.de', '.site', '.shop', '.org', '.eu']

  const unselectedExtensions = computed(() => {
    return availableExtensions.filter(s => !extensions.value.includes(s))
  })

  function add () {
    if (!input.value) return
    extensions.value.push(input.value)
    input.value = null
  }

  function remove (idx) {
    extensions.value.splice(idx, 1)
  }

  return {
    unselectedExtensions,
    availableExtensions,
    extensions,
    input,
    add,
    remove
  }
}

export function provideExtensionWhitelist () {
  const inst = createExtensionWhitelist(...arguments)
  provide(SiteBlackListKey, inst)
  return inst
}

export function useExtensionWhitelist () {
  const inst = inject(SiteBlackListKey)

  if (!inst) {
    throw new Error('Run provideExtensionWhitelist before useExtensionWhitelist')
  }

  return inst
}
