import { ref, provide, inject } from 'vue'

export const SnapshotUnsubscriberKey = Symbol('Loader')

export function createSnapshotUnsubscriber () {
  const snapshotListeners = ref([])

  function addSnapshotListener (unsubscribeCallback) {
    snapshotListeners.value.push(unsubscribeCallback)
  }

  function unsubscribeAll () {
    let listenersLength = snapshotListeners.value.length
    while (listenersLength--) {
      const unsubscribe = snapshotListeners.value[listenersLength]
      unsubscribe()
      snapshotListeners.value.splice(listenersLength - 1, 1)
    }
  }

  return {
    addSnapshotListener,
    unsubscribeAll
  }
}

export function provideSnapshotUnsubscriber () {
  const inst = createSnapshotUnsubscriber()
  provide(SnapshotUnsubscriberKey, inst)

  return inst
}

export function useSnapshotUnsubscriber () {
  const inst = inject(SnapshotUnsubscriberKey)
  if (!inst) {
    throw new Error('Run provideSnapshotUnsubscriber before useSnapshotUnsubscriber')
  }

  return inst
}
