import { ref } from 'vue'

export function useDebounce (timeoutCount = 100) {
  let timeoutRef = null
  const debouncedValue = ref('')
  const value = ref('')

  function clearTimeoutRef () {
    clearTimeout(timeoutRef)
  }

  const setValue = v => (value.value = v)
  const setDebouncedValue = v => (debouncedValue.value = v)

  const debounce = input => {
    if (timeoutRef !== null) {
      clearTimeout(timeoutRef)
    }

    value.value = input
    timeoutRef = setTimeout(() => {
      debouncedValue.value = input
    }, timeoutCount)
  }

  return { debouncedValue, setDebouncedValue, setValue, debounce, clearTimeoutRef }
}
export default useDebounce
