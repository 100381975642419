import { faTimes as farTimes } from '@fortawesome/pro-light-svg-icons'
import { faExternalLink as farExternalLink } from '@fortawesome/pro-regular-svg-icons'
import {
  faEye,
  faEyeSlash,
  faSpinner,
  faUser,
  faHome,
  faCog,
  faTrash,
  faChevronDown,
  faClock,
  faSortAlphaDown,
  faSortAlphaUp,
  faHandPaper
} from '@fortawesome/pro-solid-svg-icons'

export default [
  faEye,
  faEyeSlash,
  faSpinner,
  faUser,
  faHome,
  faCog,
  farTimes,
  farExternalLink,
  faTrash,
  faChevronDown,
  faClock,
  faSortAlphaDown,
  faSortAlphaUp,
  faHandPaper
]
