import { inject, provide, ref } from 'vue'

const SiteBlackListKey = Symbol('SiteBlackListKey')

export function createSiteBlacklist (currentSiteBlacklist) {
  const input = ref('')
  const sites = ref(currentSiteBlacklist)

  function add () {
    if (input.value.trim() === '') return
    if (sites.value.includes(input.value)) return

    sites.value.push(input.value)
    input.value = ''
  }

  function remove (idx) {
    sites.value.splice(idx, 1)
  }

  return {
    sites,
    input,
    add,
    remove
  }
}

export function provideSiteBlacklist () {
  const inst = createSiteBlacklist(...arguments)
  provide(SiteBlackListKey, inst)
  return inst
}

export function useSiteBlacklist () {
  const inst = inject(SiteBlackListKey)

  if (!inst) {
    throw new Error('Run provideSiteBlacklist before useSiteBlacklist')
  }

  return inst
}
