import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import libraryIcons from './fontAwesome'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { SnapshotUnsubscriberKey, createSnapshotUnsubscriber } from './features/SnapshotUnsubscriber/useSnapshotUnsubscriber.js'

library.add(...libraryIcons)

const toastOptions = {
  position: POSITION.BOTTOM_RIGHT
}

createApp(App)
  .use(router)
  .use(Toast, toastOptions)
  .provide(SnapshotUnsubscriberKey, createSnapshotUnsubscriber())
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
