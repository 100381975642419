<template>
  <div class="spacer-container">
    {{ text }}
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VSpacer',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang='scss' scoped>
  .spacer-container {
    display: inline-flex;
    align-items: center;
    padding-left: 15px;
    background-color: $primary;
    color: white;
    font-weight: bold;
    font-size: 14px;
    height: 35px;
    width: 100%;
    max-width: 650px;
    border-radius: 4px;
  }
</style>
