import moment from 'moment'
import { inject, provide, ref } from 'vue'
import firebase from '../../firebase.js'
import { usePriceStatus } from '../PriceStatus/usePriceStatus.js'
import { useSnapshotUnsubscriber } from '../SnapshotUnsubscriber/useSnapshotUnsubscriber.js'
import { useUser } from '../User/useUser.js'
import { useProductPrice } from './useProductPrice.js'

const ProductsKey = Symbol('ProductsKey')

export function createProducts () {
  const products = ref([])
  const loading = ref(true)
  const { addSnapshotListener } = useSnapshotUnsubscriber()
  const { user, client: { settings } } = useUser()

  function unixToHuman (input) {
    return moment(input, 'X').locale('nl').fromNow()
  }

  async function findProduct (productId) {
    return await firebase.firestore()
      .collection('clients')
      .doc(user.client)
      .collection('products')
      .doc(productId)
      .get()
  }

  async function updateProduct (productId, data, transaction = null) {
    const product = await findProduct(productId)

    if (!transaction) {
      return await product.ref.update(data)
    }

    transaction.update(product.ref, data)
  }

  function getProductIndex (productId) {
    return products.value.findIndex(product => product && product.id === productId)
  }

  async function deleteProduct (productId) {
    const product = await findProduct(productId)
    await product.ref.delete()
  }

  async function toggleEnable (productId, v, transaction = null) {
    const longTimeAgo = new Date()
    longTimeAgo.setMonth(longTimeAgo.getMonth() - 48)

    const data = {
      enabled: v,
      crawlStatus: v ? 'WAITING' : 'UIT'
    }

    if (v) data.lastCrawl = longTimeAgo

    await updateProduct(productId, data, transaction)
  }

  async function getProducts () {
    loading.value = true

    const ref = firebase.firestore()
      .collection('clients')
      .doc(user.client)
      .collection('products')
      .orderBy('enabled', 'desc')

    const unsubscribe = ref.onSnapshot(snapshot => {
      let docsToApply = []

      if (products.value.length !== 0) {
        snapshot.docChanges().forEach(change => {
          switch (change.type) {
            case 'added':
              docsToApply.push(change.doc)
              break
            case 'modified':
              docsToApply.push(change.doc)
              break
            case 'removed':
              // eslint-disable-next-line no-case-declarations
              const index = getProductIndex(change.doc.id)

              // Already deleted by ourself (using prob. deleteProduct())
              if (index === -1) return

              products.value.splice(index, 1)
              break
          }
        })
      } else {
        docsToApply = snapshot.docs
      }

      docsToApply.forEach(doc => {
        const productIndex = getProductIndex(doc.id)
        const data = doc.data()
        const { maxPrice, minPrice, target } = useProductPrice(settings, data)
        const { priceClass, priceText, priceStatusOrder } = usePriceStatus(data.currentPrice, maxPrice(), minPrice(), data.lowestPrice)

        Object.assign(data, target)
        data.lastCrawlHuman = unixToHuman(data.lastCrawl.seconds)
        data.minPrice = minPrice()
        data.maxPrice = maxPrice()
        data.priceClass = priceClass
        data.priceText = priceText
        data.priceStatusOrder = priceStatusOrder

        // Update existing product
        if (productIndex !== -1) {
          Object.assign(products.value[productIndex].data, data)
          return
        }

        // Create new product row
        products.value.push({
          id: doc.id,
          data
        })
      })

      loading.value = false
    })
    addSnapshotListener(unsubscribe)
  }

  getProducts()

  return {
    getProductIndex,
    toggleEnable,
    deleteProduct,
    findProduct,
    updateProduct,
    products,
    loading
  }
}

export function provideProducts () {
  const inst = createProducts(...arguments)
  provide(ProductsKey, inst)
  return inst
}

export function useProducts () {
  const inst = inject(ProductsKey)

  if (!inst) {
    throw new Error('Run provideProducts before useProducts')
  }

  return inst
}
