<template>
  <th @click="setCurrentSort">
    <div class="table-heading is-clickable" :class="{ 'is-sorted': field === sort }">
      <div>
        <slot />
      </div>
      <div class="sort-controls">
        <font-awesome-icon v-if="sortDirection === 'ASC'" class="icon" icon="sort-alpha-down" />
        <font-awesome-icon v-else class="icon" icon="sort-alpha-up" />
      </div>
    </div>
  </th>
</template>

<script>
import { useSortable } from './useSortable.js'

export default {
  name: 'VTableHeadSortable',
  props: {
    field: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const { sort, sortDirection } = useSortable()

    function setCurrentSort () {
      if (sort.value !== props.field) {
        sort.value = props.field
        return
      }

      if (sortDirection.value === 'ASC') {
        sortDirection.value = 'DESC'
        return
      }

      if (sortDirection.value === 'DESC') {
        sort.value = null
        sortDirection.value = 'ASC'
      }
    }

    return {
      sort,
      sortDirection,
      setCurrentSort
    }
  }
}
</script>

<style lang='scss' scoped>
.table-heading {
  display: flex;
  flex-direction: row;
  user-select: none;

  &.is-clickable {
    cursor: pointer;
  }

  &.is-sorted {
    color: #E5853B;
  }
  .sort-controls {
    transition: inherit;
    margin-left: 5px;
  }
}
</style>
