import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyB4Ku8qrltQu_Y2NZDA1aDJzN7SvXL7SUI',
  authDomain: 'dactec-pricing.firebaseapp.com',
  projectId: 'dactec-pricing',
  storageBucket: 'dactec-pricing.appspot.com',
  messagingSenderId: '953334290328',
  appId: '1:953334290328:web:2a1ed593f0817f74003ded',
  measurementId: 'G-0BJ3XWG13E'
}
firebase.initializeApp(firebaseConfig)

export default firebase
