import { computed } from 'vue'

export function useFilter (searchableArray, selectedFilter) {
  const filteredResult = computed(() => {
    switch (selectedFilter.value) {
      case 'nocomp':
        return searchableArray.value.filter(r => r.data.amountCompetitors === 0)
      case 'comphighermax':
        return searchableArray.value.filter(r => r.data.hasCompetitorWithHigherMaxPrice === true)
      case 'notsold7':
        return searchableArray.value.filter(r => r.data.notSoldPastSevenDays === 0)
      case 'stock':
        return searchableArray.value.filter(r => r.data.stockStatus === 'in_stock')
      case 'nostock':
        return searchableArray.value.filter(r => r.data.stockStatus === 'not_in_stock')
      case 'fixedprice':
        return searchableArray.value.filter(r => r.data.fixedPrice === true)
      case false:
      default:
        return searchableArray.value
    }
  })

  return {
    filteredResult
  }
}
