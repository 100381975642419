<template>
  <div class="status-circle" :title="priceText" :class="priceClass"></div>
</template>

<script>
export default {
  name: 'StatusCircle',
  props: {
    priceText: {
      type: String,
      default: ''
    },
    priceClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.status-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  &.is-danger {
    background-color: #E53B3B;
  }
  &.is-warning {
    background-color: #E5853B;
  }
  &.is-info {
    background-color: #3B8BE5;
  }
  &.is-success {
    background-color: #3CAB51;
  }
  &.is-off {
    background-color: #C7C7C7;
  }
  &.is-purple {
    background-color: #663399;
  }
}
</style>
