<template>
  <div class='login-container'>
    <div class='wrapper'>
      <div class='title'>PriceTiger</div>
      <form class='form' @submit.prevent='login'>
        <VField label-left="E-mail adres" layout="is-column">
          <VInput v-model='email' placeholder='Email' />
        </VField>
        <VField label-left="Wachtwoord" layout="is-column">
          <VInput v-model='password' type='password' placeholder='Password' />
        </VField>
        <div class="controls">
          <VButton :loading="loading" class="submit" type="submit">Inloggen</VButton>
          <span class='error-message'>{{ message }}</span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import firebase from '../firebase.js'
import VButton from './ComponentFramework/Button/VButton.vue'
import VField from './ComponentFramework/Field/VField.vue'
import VInput from './ComponentFramework/Input/VInput.vue'

export default {
  name: 'Start',
  components: { VButton, VField, VInput },
  setup () {
    const { push } = useRouter()
    const state = reactive({
      email: '',
      password: '',
      message: null,
      loading: false
    })

    function login () {
      state.loading = true
      firebase.auth().signInWithEmailAndPassword(state.email, state.password)
        .then((r) => {
          push({ name: 'products' })
        })
        .catch(e => {
          state.message = e.message
        })
        .finally(() => {
          state.loading = false
        })
    }

    return {
      login,
      ...toRefs(state)
    }
  }
}
</script>

<style lang='scss' scoped>
.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/tijger.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .error-message {
    color: red;
  }
}

.wrapper {
  padding: 25px;
  width: 350px;
  height: 368px;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  border-radius: 8px;

  .form {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;

    .controls {
      display: flex;
      align-items: center;

      .submit {
        width: 115px;
      }
      .error-message {
        margin-left: 10px;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}

</style>
