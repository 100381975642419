<template>
  <div class="modal-container" @mousedown.self.stop="modal.options.onCancel">
    <div class="modal">
      <div class="header">
        <span class="title">{{ modal.options.title }}</span>
        <span class="close" @click="modal.options.onCancel">
          <font-awesome-icon class="icon" :icon="['fal', 'times']" />
        </span>
      </div>
      <div class="body">
        <slot />
        <component :is="componentInst" :manager="modal" v-bind="modal.options" />
      </div>
    </div>
  </div>
</template>

<script>
import { useModal } from './Modal.js'

export default {
  name: 'VModal',
  setup () {
    const modal = useModal()
    const { componentInst } = modal
    return { componentInst, modal }
  }
}
</script>

<style lang='scss' scoped>
@import './Modal';

  .header {
    .title {
      margin-bottom: 19px;
    }
  }
  .modal-container {
    &::v-deep(form .field.is-row.has-label-left) {
      padding-left: 0;
    }
  }
</style>
