<template>
  <div class="navbar-container">
    <div class="items">
      <div class="logo">
        <img class="image" src="../../../../assets/logo.png" />
      </div>
      <div class="navbar-links">
        <div class="item is-unclickable">
          <font-awesome-icon :icon="['fas', 'user']" class="icon" />
          <div class="title">
            {{ user.name }}
          </div>
        </div>
        <router-link tag="div" class="item" to="/products">
          <font-awesome-icon :icon="['fas', 'home']" class="icon" />
          <div class="title">
            Overzicht
          </div>
        </router-link>
        <router-link tag="div" class="item" to="/settings">
          <font-awesome-icon :icon="['fas', 'cog']" class="icon" />
          <div class="title">
            Instellingen
          </div>
        </router-link>
      </div>
    </div>
    <div class="logout" @click="logout">
      Uitloggen
    </div>
  </div>
</template>

<script>
import firebase from '@/firebase.js'
import { useSnapshotUnsubscriber } from '../../SnapshotUnsubscriber/useSnapshotUnsubscriber.js'
import { useUser } from '../../User/useUser.js'

export default {
  name: 'Navbar',
  setup () {
    const { user } = useUser()
    const { unsubscribeAll } = useSnapshotUnsubscriber()

    function logout () {
      unsubscribeAll()
      firebase.auth().signOut()
    }

    return {
      logout,
      user
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-container {
  position: relative;
  z-index: 5;
  width: 90px;
  max-width: 90px;
  min-width: 90px;
  height: 100vh;
  background-color: $primary;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 100%;
    color: white;
    justify-content: center;
    display: flex;
    margin-top: 30px;

    img {
      width: 75px;
      height: 75px;
    }

    &::v-deep(.v) {
      fill: var(--text-invert);
    }
  }

  .items {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .navbar-links {
      margin-top: auto;
      margin-bottom: auto;
    }

    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      width: 100%;
      padding: 20px 0;
      transition: ease all 100ms;
      text-decoration: none;

      &.is-unclickable {
        cursor: initial;
        user-select: none;
      }

      &:hover, &.router-link-exact-active, &.is-active {
        &:not(.is-unclickable) {
          background-color: $primary-dark;
        }
      }

      &.router-link-exact-active {
        .title {
          font-weight: bold;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        color: var(--text-invert);
        font-size: 30px;
      }

      .title {
        text-align: center;
        word-break: break-word;
        max-width: 85%;
        color: var(--text-invert);
        font-size: 12px;
        margin-top: 10px;
        font-weight: 400;
      }
    }
  }

  .notification-item {
    color: var(--text-invert);
    padding-top: 10px;
    user-select: none;
    cursor: initial;
    margin-bottom: 20px;

    .icon {
      font-size: 20px;
      position: relative;
      cursor: pointer;
    }

    &:hover {
      background-color: initial;
    }

    .badge-container {
      position: relative;

      .badge {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 17px;
        height: 16px;
        text-align: center;
        vertical-align: center;
        line-height: 17px;
        font-weight: bold;
        background-color: $danger;
        font-size: 10px;
        color: var(--text-invert);
        border-radius: 20px;
        z-index: 10;
      }
    }
  }

  .theme-switch {
    color: var(--text-invert);
    margin-bottom: 15px;
    cursor: pointer;

    .icon {
      transition: transform 150ms ease-in-out;
    }
  }

  .logout {
    margin-top: auto;
    margin-bottom: 30px;
    color: var(--text-invert);
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      color: #e0e0e0;
    }
  }

  .version {
    margin-top: 15px;
    margin-bottom: 25px;
    color: var(--text-invert);
    font-size: 12px;
  }
}

@media only screen and (max-height: 700px) {
  .navbar-container {
    .items {
      .notification-item {
        .title {
          display: none;
        }
      }

      .item {
        padding: 15px 0;

        .icon {
          font-size: 25px;
        }
      }
    }
  }
}
</style>
