<template>
  <div class="input-wrapper">
    <div class="input">
      <input
        v-model="valueRef"
        :name="name"
        :type="computedType"
        :class="[{
          'has-icon-left': iconLeft,
          'has-icon-right': iconRight,
          'has-border': hasBorder,
        }, variant]"
        v-bind="$attrs"
      >
    </div>
    <p v-show="errorMessage" class="validator-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useValue, inputMixin } from '../Value/useValue.js'

export default {
  name: 'VInput',
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    name: {
      type: [String, Boolean],
      default: false
    },
    type: {
      type: [String, Boolean],
      default: 'text'
    },
    rules: {
      type: Array,
      default: () => []
    },
    iconLeft: {
      type: [Boolean, String, Object],
      default: false
    },
    iconRight: {
      type: [Boolean, String, Object],
      default: false
    },
    variant: {
      type: String,
      default: 'is-default'
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const revealPass = ref(false)
    const { valueRef } = useValue(props, emit)

    const computedType = computed(() => {
      if (props.type !== 'password') {
        return props.type
      }
      if (revealPass.value) {
        return 'text'
      }
      return props.type
    })

    const computedIconRight = computed(() => {
      if (props.type === 'password') {
        if (revealPass.value) {
          return 'eye-slash'
        }
        return 'eye'
      }

      return props.iconRight
    })

    return { revealPass, computedIconRight, computedType, valueRef }
  }
}
</script>

<style lang='scss' scoped>
.input-wrapper {
  flex: 1 1 66%;
  position: relative;
}

.input {
  position: relative;
  display: flex;
  align-items: center;

  .icon {
    position: absolute;
    width: 11px;
    color: $primary;
    font-size: 12px;

    &.left {
      left: 0;
      margin-left: 15px;
    }

    &.right {
      margin-right: 15px;
      right: 0;

      &.has-action {
        cursor: pointer;

        &:hover {
          color: darken($primary, 10%);
        }
      }
    }
  }

  input {
    width: 100%;
    height: 32px;
    border-radius: 50px;
    border: none;
    padding: 0 0 0 15px;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;

    &.is-default {
      background-color: var(--input-background);
      color: var(--input-text);
      border: 1px solid #CECCCC;

      &::placeholder {
        color: var(--input-placeholder);
      }
    }

    &.has-border {
      border: 1px solid $primary;
    }

    &:focus {
      outline: none;
      background-color: var(--input-background-active);
    }

    &.has-icon-left {
      padding-left: 33px;
    }

    &.has-icon-right {
      padding-right: 33px;
    }
  }
}
</style>
