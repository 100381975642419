<template>
  <div class="divider">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VDivider'
}
</script>

<style lang="scss" scoped>
.divider {
  color: #C7C7C7;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  display: flex;
  flex: 1 100%;
  margin-bottom: 8px;

  &.no-margins {
    margin-bottom: 0;
    &::after {
      margin-left: 0;
    }
  }

  &::after {
    display: inline-flex;
    height: 1px;
    content: " ";
    text-shadow: none;
    background-color: #C7C7C7;
    flex: 2 auto;
    margin-left: 20px;
    margin-bottom: auto;
    bottom: auto;
    top: auto;
    margin-top: auto;
  }
}
</style>
