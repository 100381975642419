import { inject, provide, reactive, ref } from 'vue'
import firebase from '../../firebase.js'

const UserKey = Symbol('User')

export function createUser () {
  const clientDoc = ref(null)
  const client = reactive({})
  const user = reactive({})

  async function setUser ({ uid, email }) {
    Object.assign(user, { uid, email })
    await fetchUserExtras()
  }

  function setUserExtras (extras) {
    Object.assign(user, extras)
  }

  async function fetchClient () {
    clientDoc.value = await firebase.firestore().doc(`clients/${user.client}`).get()
    Object.assign(client, clientDoc.value.data())
  }

  async function fetchUserExtras () {
    await firebase.firestore().collection('users')
      .doc(user.uid)
      .get()
      .then(extras => setUserExtras(extras.data()))
    await fetchClient()
  }

  return {
    user,
    client,
    clientDoc,
    setUser
  }
}

export function provideUser () {
  const instance = createUser()
  provide(UserKey, instance)
  return instance
}

export function useUser () {
  const instance = inject(UserKey)

  if (!instance) {
    throw new Error('User error')
  }

  return instance
}
