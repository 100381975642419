import { computed } from 'vue'

export function useSearch (searchableArray, searchTerm) {
  function lc (s = '') {
    return s.toLowerCase()
  }

  const searchResult = computed(() => {
    if (searchTerm.value.trim() !== '') {
      return searchableArray.value.filter(product => {
        return (product.data.EAN + '').includes(searchTerm.value) ||
          lc(product.data.name).includes(lc(searchTerm.value))
      })
    }
    return searchableArray.value
  })

  return {
    searchResult
  }
}
