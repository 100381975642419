<template>
  <div class="select-container">
    <div class="select"
         :class="[{
           'has-icon-left': iconLeft,
           'has-icon-right': iconRight
         }, variant]"
    >
      <div v-if="iconLeft" class="icon left">
        <font-awesome-icon :icon="iconLeft" />
      </div>
      <div v-if="clearable && valueRef !== null" class="icon clearable" @click="clear">
        <font-awesome-icon :icon="['fas', 'times']" />
      </div>
      <div v-if="iconRight" class="icon right">
        <font-awesome-icon :icon="iconRight" />
      </div>
      <select
        v-bind="$attrs"
        ref="selectElement"
        v-model="valueRef"
        class="v-select"
        :name="name || undefined"
      >
        <option disabled selected :value="null">
          {{ placeholder }}
        </option>
        <slot />
      </select>
    </div>
    <p v-show="errorMessage" class="validator-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { useValue, selectMixin } from '../Value/useValue.js'
import { ref } from 'vue'

export default {
  name: 'VSelect',
  mixins: [selectMixin],
  inheritAttrs: false,
  props: {
    name: {
      type: [String, Boolean],
      default: false
    },
    iconLeft: {
      type: [Boolean, String, Object],
      default: false
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
    iconRight: {
      type: [Boolean, String, Array],
      default: () => ['fas', 'chevron-down']
    },
    placeholder: {
      type: String,
      default: 'PLACEHOLDER'
    },
    rules: {
      type: Array,
      default: () => []
    },
    variant: {
      type: String,
      default: 'is-default'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const selectElement = ref(null)
    const { valueRef } = useValue(props, emit)

    function clear () {
      valueRef.value = null
    }

    return { valueRef, clear, selectElement }
  }
}
</script>

<style lang='scss' scoped>
.select-container {
  flex: 1 1 66%;
  position: relative;
}
.select {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.is-default {
    .v-select {
      background-color: var(--input-background);
      color: var(--input-text);
      border: 1px solid #CECCCC;

      &::placeholder {
        color: var(--input-placeholder);
      }
    }
  }

  &.is-white {
    .v-select {
      background-color: white;
      color: var(--input-text);
      border: 1px solid #CECCCC;

      &::placeholder {
        color: var(--input-placeholder);
      }
    }
  }

  .icon {
    position: absolute;
    width: 11px;
    color: var(--text);
    font-size: 13px;

    &.left {
      left: 0;
      margin-left: 15px;
    }
    &.clearable {
      margin-right: 15px;
      right: 15px;
      height: 11px;
      cursor: pointer;
      &:hover {
        color: darken($primary, 10%);
      }
    }
    &.right {
      margin-right: 15px;
      right: 0;
      height: 11px;

      &:not(.has-action) {
        pointer-events: none;
      }

      &.has-action {
        cursor: pointer;

        &:hover {
          color: darken($primary, 10%);
        }
      }
    }
  }

  .v-select {
    width: 100%;
    height: 32px;
    border-radius: 50px;
    background-color: var(--input-background);
    border: none;
    padding: 0 15px;
    font-size: 14px;
    color: var(--input-text);
    font-family: 'Roboto', sans-serif;
    transition: 100ms ease background-color;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;

    &::placeholder {
      color: var(--input-placeholder);
    }

    &:focus {
      outline: none;
      background-color: var(--input-background-active);
    }

    &.has-icon-left {
      padding-left: 33px;
    }

    &.has-icon-right {
      padding-right: 33px;
    }
  }
}
</style>
